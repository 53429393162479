import React, { useRef, useState } from 'react';
import { Link } from 'gatsby';
import $ from 'jquery';
import styled from 'styled-components';

import Layout from '../components/layout';
import SEO from '../components/seo';

const Profile = styled.div`
  text-align: center;
  @media (min-width: 768px) { 
    text-align: right;
  }
`;

function getStarted() {
  function animateBox() {
    setTimeout(() => {
      $('#contact').addClass('contact-active');
      setTimeout(() => {
        $('#contact').removeClass('contact-active');
      }, 400);
    }, 400);
  }

  const $target = $('#contact');
  $('html, body').stop().animate({
    scrollTop: $target.offset().top,
  }, 500, 'swing', animateBox);
}

/*
const busyBanner = (
  <p style={{ color: 'rgba(170, 0, 0, 1)', textAlign: 'center', marginTop: '1em', maxWidth: 600, marginLeft: 'auto', marginRight: 'auto' }}>
    I&#39;m currently busy, but if you have an project you need realized, contact me and I can help you estimate the work. Also check out the consultant network <a href="https://brainbase.no/">BrainBase.no</a> for other consultants.
  </p>
);
*/
const busyBanner = null;

const customers = [
  'EPSYS',
  'Skiltnett',
  'iFind UG',
  'Infopix',
  'NEW by Sigma',
  'Giant Leap',
  'FamWeek',
  'SwimClips',
  'EffectiveLearning',
  'Presis Vegdrift',
];

export default () => {
  const [showVideo, setShowVideo] = useState(false);
  const videoRef = useRef();

  const cubesStyle = {
    fontSize: '7em',
    padding: '.2em .3em',
  };

  function onVidPlay() {
    setShowVideo(true);
  }

  return (
    <Layout>
      <SEO title="Home" />

      <div style={{ backgroundColor: 'white' }}>
        <div style={{ padding: '3em 1em' }}>
          <div className="container">
            <div className="row">
              <div className="col-sm-4 hidden-xs" style={{ textAlign: 'right' }}>
                <i className="fa fa-cubes" style={cubesStyle} />
              </div>

              <div className="visible-xs" style={{ fontSize: '.8em', textAlign: 'center', marginBottom: '3em' }}>
                <i className="fa fa-cubes" style={cubesStyle} />
              </div>

              <div className="col-sm-8">
                <h2>I create apps</h2>

                <p>
                  I provide complete software development services. I create cost effective, batteries-included solutions on platforms like web, mobile, desktop and cloud, using the modern JavaScript ecosystem and open source.<br />
                </p>
              </div>
            </div>

            <div style={{ paddingTop: '5em' }}>
              <div className="row">
                <div className="col-sm-4 hidden-xs">
                  <div style={{ fontSize: '2.3em', paddingBottom: '1em', width: '5.2em', marginRight: 0, marginLeft: 'auto', whiteSpace: 'nowrap' }}>
                    <i className="fa fa-desktop" style={{ fontSize: '3em' }} />
                    <i className="fa fa-tablet" style={{ fontSize: '1.5em' }} />
                    <i className="fa fa-mobile" style={{ fontSize: '1em' }} />
                  </div>
                </div>

                <div className="visible-xs" style={{ fontSize: '3em', paddingBottom: '1em', textAlign: 'center' }}>
                  <i className="fa fa-desktop" style={{ fontSize: '3em' }} />
                  <i className="fa fa-tablet" style={{ fontSize: '1.5em' }} />
                  <i className="fa fa-mobile" style={{ fontSize: '1em' }} />
                </div>

                <div className="col-sm-8">
                  <h2>In all sizes</h2>
                  <ul>
                    <li>Cross-platform mobile apps for iOS &amp; Android (<b>React Native</b>)</li>
                    <li>Web applications (<b>React</b>)</li>
                    <li>Desktop applications for Windows, Mac and Linux (cross platform <b>Electron</b>)</li>
                    <li>Backend cloud applications (<b>Node.js, AWS</b>)</li>
                    <li>DevOps (<b>AWS, Google Cloud, GitHub, CI</b>)</li>
                    <li>Applications for specialized embedded Linux computers (<b>Raspberry Pi</b>)</li>
                  </ul>
                </div>
              </div>
            </div>

            <div style={{ padding: '5em 0' }}>
              <div className="row">
                <div className="col-sm-4 hidden-xs" />

                <div className="col-sm-8">
                  <h2>How does it work?</h2>

                  <p>Need an idea or a potential project realized? I provide development of all the software needed to realize your project through an iterative development process. This means I provide rapid, continous feedback and the customer can continously track the progress of development through frequent test releases and management tools like GitHub.</p>
                </div>
              </div>

              <p style={{ marginTop: '4em', textAlign: 'center' }}><Link to="/projects" className="big-button" style={{ backgroundColor: '#363636' }}>Previous projects</Link></p>
            </div>

            <div style={{ paddingBottom: '3em' }}>
              <div className="row">
                <Profile className="col-sm-4">
                  <img className="img-circle" src="/images/me.jpg" alt="" style={{ border: '.2em solid #AEAEAE', width: '10em', margin: '1em' }} />
                </Profile>

                <div className="col-sm-8">
                  <h2>Who am I?</h2>
                  <ul>
                    <li>{(new Date().getFullYear() - 2007)} years of professional experience</li>
                    <li>Passion for creating excellent software</li>
                    <li>BSc in Software development</li>
                    <li>Experienced with media/video processing and automation.</li>
                    <li>Creator of the popular video editor <a style={{ fontWeight: 'bold' }} href="https://github.com/mifi/lossless-cut" target="_blank" rel="noopener noreferrer">LosslessCut</a></li>
                    <li>See more <a style={{ fontWeight: 'bold' }} href="https://github.com/mifi" target="_blank" rel="noopener noreferrer">GitHub projects</a></li>
                    <li><a style={{ fontWeight: 'bold' }} href="https://mifi.no">mifi.no</a></li>
                  </ul>
                </div>
              </div>

              {busyBanner}

              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
              <p style={{ marginTop: '2em', textAlign: 'center' }}><span tabIndex="0" role="button" onClick={getStarted} className="get-started big-button" style={{ backgroundColor: '#D5392D' }}>Contact me</span></p>
            </div>

          </div>
        </div>

        <div className="pre-footer" style={{ textTransform: 'uppercase', padding: '2em' }}>
          <div style={{ fontSize: '1.5em', textAlign: 'center', marginBottom: '1.5em' }}>Customers</div>
          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between', maxWidth: 600, margin: 'auto' }}>
            {customers.map(c => (
              <div key={c} style={{ fontWeight: 'bold', fontSize: '1.5em', padding: '.5em 2em .5em 0' }}>{c}</div>
            ))}
          </div>
        </div>
      </div>

      <video ref={videoRef} style={{ width: 1170, maxWidth: '100%', margin: 'auto', display: 'block', borderBottomLeftRadius: 10, borderBottomRightRadius: 10, height: showVideo ? undefined : 0 }} src="/drone-header.mp4" playsInline autoPlay loop muted onPlay={onVidPlay} />
    </Layout>
  );
};
